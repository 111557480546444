<template>
  <div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="——已经到底了——"
      @load="onLoad"
    >
      <ul class="p_log">
        <li>
          <div class="p_l_info">
            <p>积分退换</p>
            <span>2020.08.24 13:10:59</span>
          </div>
          <span class="p_point g">-299</span>
        </li>
        <li>
          <div class="p_l_info">
            <p>消费购物</p>
            <span>2020.08.24 13:10:59</span>
          </div>
          <span class="p_point">+299</span>
        </li>
        <li>
          <div class="p_l_info">
            <p>签到</p>
            <span>2020.08.24 13:10:59</span>
          </div>
          <span class="p_point">+1</span>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import { List } from "vant";
export default {
  components: {
    vanList: List,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
::v-deep {
  .van-list__finished- text {
    color: #c1c1c1;
    font-size: rem(22);
  }
}
.p_log {
  width: rem(750);
  margin: 0 auto;
  li {
    width: rem(690);
    margin: 0 auto;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: rem(30) 0;
    border-bottom: 1px solid #eeeeee;
    .p_l_info {
      flex: 1;
      p {
        color: #222222;
        font-size: rem(30);
        @include ell;
      }
      span {
        color: #989898;
        font-size: rem(22);
      }
    }
    .p_point {
      color: #ff4848;
      font-size: rem(30);
    }
    .g {
      color: #01b393;
    }
  }
}
</style>
